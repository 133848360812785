import { Col, Row } from 'react-bootstrap'

import CartIcon from '../CartIcon'
import GlobalClasses from '../../styles/global'
import { Link } from 'gatsby'
import { ProductLine } from '../../apollo/generated.d'
import React from 'react'
import { createUseStyles } from 'react-jss'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { useTranslation } from 'react-i18next'

const useStyles = createUseStyles((theme) => ({
  ...GlobalClasses,
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  floatingContainer: {
    background: '#fafafa',
    position: 'fixed',
    zIndex: '1300',
    left: '0px',
    top: '0px',
    width: '100%',
    display: 'flex',
  },

  list: {
    marginTop: '5px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    alignItems: 'stretch',
  },

  listFloating: {
    width: 'initial',
    maxWidth: '1500px',
    margin: 'auto',
  },

  item: {
    margin: '6px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    width: '120px',
    height: '121px',
    boxShadow: '2px 2px 10px rgba(166, 171, 189, 0.4), -2px -2px 10px #ffffff',
    borderRadius: '10px',
    backgroundColor: '#fafafa',

    '&:hover': {
      textDecoration: 'none',
      background: 'linear-gradient(166.27deg, #FAFAFA 0%, #F0F3F8 100%)',
      boxShadow:
        'inset 2px 2px 4px rgba(166, 171, 189, 0.3), inset -2px -2px 4px #ffffff;',
    },

    '& img': {
      width: 'auto',
      height: '55px',
      objectFit: 'cover',
    },

    '& div': {
      color: theme.colors.darkGrey,
      fontSize: '14px',
      fontWeight: '600',
      textTransform: 'uppercase',
      width: '100%',
      marginTop: '11px',
    },
  },

  itemFloating: {
    boxShadow: 'none',
    width: '175px',
    height: '71px',
    padding: '15px',
    flexDirection: 'row',
    '& img': {
      marginLeft: '10px',
    },
    '& div': {
      width: '100%',
      textAlign: 'center',
      marginTop: '3px',
    },
    [`@media (max-width: ${theme.breakpoints.XL})`]: {
      padding: '5px',
      width: 'auto',
      maxWidth: '120px',
      minWidth: '85px',
      flexDirection: 'column',
      '& img': {
        marginLeft: '0px',
        padding: '5px',
      },
    },
    [`@media (max-width: ${theme.breakpoints.S})`]: {
      '& img': {
        padding: '5px',
      },
    },
  },

  itemSelected: {
    textDecoration: 'none',
    background: 'linear-gradient(166deg, #fafafa 0%, #f0f3f8 100%)',
    boxShadow:
      'inset 2px 2px 4px rgba(166, 171, 189, 0.3), inset -2px -2px 4px #ffffff;',
    borderRadius: '10px',
  },

  title: {
    color: theme.colors.primaryGrey,
    fontSize: '18px',
    fontWeight: 300,
    letterSpacing: '0.75px',
    textTransform: 'none',
  },

  titleFloating: {
    color: theme.colors.primaryGrey,
    fontSize: '16px',
    fontWeight: 300,
    letterSpacing: '0.67px',
    textTransform: 'none',
    paddingTop: '15px',
  },

  shoppingCartIconWrapper: {
    width: '100px',
    height: '60px',
    float: 'right',
    paddingTop: '23px',
    [`@media (max-width: ${theme.breakpoints.S})`]: {
      width: '50px',
      '& a': {
        marginLeft: '10px',
      },
    },
  },

  leftTitleWrapper: {
    margin: 'auto',
    padding: 0,
    width: '530px',
    [`@media (max-width: ${theme.breakpoints.S})`]: {
      maxWidth: '530px',
      width: 'initial',
    },
  },
  leftTitleWrapperFloating: {
    width: '350px',
    [`@media (max-width: ${theme.breakpoints.M})`]: {
      display: 'none',
    },
  },

  leftTitleBorder: {
    margin: 0,
    padding: 0,
    marginLeft: '10px',
    marginRight: '10px',
    height: '12px',
    borderBottom: '1px solid rgba(119, 123, 145, 0.5)',
  },

  leftTitleBorderFloating: {
    borderBottom: 'none',
  },

  noPaddingMargin: {
    margin: 0,
    padding: 0,
  },

  hidden: {
    opacity: '0 !important',
  },
}))

type DataProps = {
  productViews: ProductLine[]
  productLinesRefs: React.RefObject<HTMLDivElement>[]
}

type StateProps = {
  isFloating: boolean
  selectedLine: string
}

const ProductNavigation: React.ComponentType<DataProps> = ({
  productViews,
  productLinesRefs,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [state, setState] = React.useState<StateProps>({
    isFloating: false,
    selectedLine: null,
  })

  const containerRef: React.RefObject<HTMLDivElement> = React.useRef(null)

  React.useLayoutEffect(() => {
    const scrollEvent = () => {
      if (containerRef && containerRef.current) {
        const offsetTop =
          containerRef.current.offsetTop +
          containerRef.current.offsetHeight -
          83

        let selected = null
        let minOffset = Infinity * -1
        Object.keys(productLinesRefs).forEach((key) => {
          const elementOffset =
            productLinesRefs[key].current?.offsetTop - window.pageYOffset - 160
          if (elementOffset < 0) {
            if (elementOffset > minOffset) {
              minOffset = elementOffset
              selected = key
            }
          }
        })

        if (window.pageYOffset >= offsetTop) {
          setState({ isFloating: true, selectedLine: selected })
        }
        if (window.pageYOffset < offsetTop) {
          setState({ isFloating: false, selectedLine: selected })
        }
      }
    }
    window.addEventListener('scroll', scrollEvent)
    return () => window.removeEventListener('scroll', scrollEvent)
  }, [])

  const renderStaticTiles = () => {
    return (
      <>
        <Row className={classes.leftTitleWrapper}>
          <Col xs={3} className={classes.noPaddingMargin}>
            <div className={classes.leftTitleBorder}></div>
          </Col>
          <Col
            xs={6}
            className={classes.noPaddingMargin}
            style={{ textAlign: 'center' }}
          >
            <h3 className={classes.title}>{t('HOMEPAGE_NAV_TITLE')}</h3>
          </Col>
          <Col xs={3} className={classes.noPaddingMargin}>
            <div className={classes.leftTitleBorder}></div>
          </Col>
        </Row>
        <div className={classes.list}>
          {productViews.map((productView: ProductLine, index) => (
            <div
              onClick={() => scrollTo(`#${productView.Slug}`)}
              className={`${classes.item} ${
                productView.Name === state.selectedLine
                  ? classes.itemSelected
                  : ''
              } ${state.isFloating ? classes.hidden : ''}`}
              key={`nav-item-${index}`}
            >
              <img
                src={productView.Thumbnail.url}
                alt={`Vypínače řady ${productView.Name}`}
              />
              <div>{productView.Name}</div>
            </div>
          ))}
        </div>
      </>
    )
  }

  const renderTiles = () => {
    return (
      <>
        <Row
          className={`${classes.leftTitleWrapper} ${classes.leftTitleWrapperFloating}`}
        >
          <Col xs={1} className={classes.noPaddingMargin}>
            <div
              className={`${classes.leftTitleBorder} ${classes.leftTitleBorderFloating}`}
            ></div>
          </Col>
          <Col
            xs={10}
            className={classes.noPaddingMargin}
            style={{ textAlign: 'center' }}
          >
            <h3 className={classes.titleFloating}>{t('HOMEPAGE_NAV_TITLE')}</h3>
          </Col>
          <Col xs={1} className={classes.noPaddingMargin}>
            <div
              className={`${classes.leftTitleBorder} ${classes.leftTitleBorderFloating}`}
            ></div>
          </Col>
        </Row>
        <div className={`${classes.list} ${classes.listFloating}`}>
          {productViews.map((productView: ProductLine, index) => (
            <div
              onClick={() => scrollTo(`#${productView.Slug}`)}
              className={`${classes.item} ${classes.itemFloating} ${
                productView.Name === state.selectedLine
                  ? classes.itemSelected
                  : ''
              }`}
              key={`nav-item-${index}`}
            >
              <img
                src={productView.Thumbnail.url}
                alt={t('configurator:PRODUCT_REVIEWS')}
              />
              <div>{productView.Name}</div>
            </div>
          ))}

          <div className={classes.shoppingCartIconWrapper}>
            <CartIcon />
          </div>
        </div>
      </>
    )
  }

  const renderStaticHeader = () => {
    return (
      <div className={classes.container} ref={containerRef}>
        {renderStaticTiles()}
      </div>
    )
  }

  const renderFloatingHeader = () => {
    return <div className={classes.floatingContainer}>{renderTiles()}</div>
  }

  return (
    <>
      {renderStaticHeader()}
      {state.isFloating ? renderFloatingHeader() : null}
    </>
  )
}

export default ProductNavigation
