export const sortByOrder = (faqItems: any) => {
  const compareOrder = (a: any, b: any) => {
    if (a.Order > b.Order) return 1
    if (b.Order > a.Order) return -1

    return 0
  }
  const compareId = (a: any, b: any) => {
    if (Number(a.id) > Number(b.id)) return 1
    if (Number(b.id) > Number(a.id)) return -1

    return 0
  }
  const itemsWithOrder: any[] = []
  const itemsWithoutOrder: any[] = []
  faqItems.forEach((item: any) => {
    if (item.Hidden !== true) {
      if (item.Order) {
        itemsWithOrder.push(item)
      } else {
        itemsWithoutOrder.push(item)
      }
    }
  })

  return [
    ...itemsWithOrder.sort(compareOrder),
    ...itemsWithoutOrder.sort(compareId),
  ]
}

export const sortProductSubLinesByOrder = (productSubLines: any[]) => {
  if (productSubLines?.length) {
    let sortedSubLines = [...productSubLines]

    sortedSubLines.sort((a, b) => {
      if (!a.Order) {
        return 1
      }
      if (!b.Order) {
        return -1
      }
      if (a.Order < b.Order) {
        return -1
      }
      return 1
    })

    sortedSubLines.sort((a, b) => {
      if (!a.ProductLine?.Order) {
        return 1
      }
      if (!b.ProductLine?.Order) {
        return -1
      }
      if (a.ProductLine?.Order < b.ProductLine?.Order) {
        return -1
      }
      return 1
    })
    return sortedSubLines
  }
  return []
}
