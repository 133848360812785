import HomePage from 'views/HomePage'
import Layout from 'components/Layout'
import React from 'react'
import SEO from 'components/seo'
import { useLocation } from '@reach/router'
import { sessionStore } from '../utils/StorageUtils'
import { trackIframe, trackStep } from 'utils/GtmTracking'
import { useEffect } from 'react'

type Props = {}

const IndexPage: React.ComponentType<Props> = (props: Props) => {
  const location = useLocation()
  const partnerId = new URLSearchParams(location.search).get('partnerId')

  useEffect(() => {
    if (partnerId) {
      sessionStore?.setItem('partnerId', partnerId)
      trackIframe(partnerId)
      trackStep(partnerId, 1)
    }
  }, [])

  return (
    <Layout>
      <SEO
        title="Vypínače a zásuvky Schneider Electric"
        description="Moderní, kvalitní a bezpečné přístroje za dostupnou cenu. Široká nabídka stylových vypínačů a zásuvek pro jakýkoliv interiér."
      />
      <HomePage />
    </Layout>
  )
}

export default IndexPage
