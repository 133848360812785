import { graphql, useStaticQuery } from 'gatsby'

import BlockPanel from '../components/HomePage/BlockPanel'
import NewsletterPanel from '../components/HomePage/NewsletterPanel'
import { ProductLine } from '../apollo/generated.d'
import ProductNavigation from '../components/HomePage/ProductNavigation'
import ProductPanel from '../components/HomePage/ProductPanel'
import React, { RefObject, useEffect, useState } from 'react'
import ShopsHeader from '../components/Shops/ShopsHeader'
import { createUseStyles } from 'react-jss'
import { sortByOrder } from '../utils/SortUtils'
import { useTranslation } from 'react-i18next'
import { sessionStore } from '../utils/StorageUtils'
import { gql, useQuery } from '@apollo/client'
const homePageTopBackground = require('../images/homePageTopBackground.png')

// export const GET_PRODUCT_LINES_QUERY = graphql`
//   query GetProductLines {
//     cms {
//       productLines {
//         id
//         Name
//         Slug
//         Description
//         Order
//         MoodPhoto {
//           url
//         }
//         Thumbnail {
//           url
//         }
//         Benefits {
//           id
//           BenefitDescription
//         }
//         ProductSubLines {
//           id
//           Name
//           Slug
//           Featured
//           Hidden
//           Thumbnail {
//             url
//           }
//           ProductLine {
//             Name
//             Slug
//           }
//           PriceRange
//           Order
//         }
//       }
//       blogPosts(
//         limit: 3
//         sort: "PublicationDate:DESC"
//         where: { Visible: true }
//       ) {
//         id
//         Title
//         Perex
//         Slug
//         PublicationDate
//         Categories {
//           HidePublicationDates
//         }
//         CoverPhoto {
//           url
//         }
//         created_at
//       }
//     }
//   }
// `

export const PRODUCT_LINES_QUERY = gql`
  query {
    productLines {
      id
      Name
      Slug
      Description
      Order
      MoodPhoto {
        url
      }
      Thumbnail {
        url
      }
      Benefits {
        id
        BenefitDescription
      }
      ProductSubLines {
        id
        Name
        Slug
        Featured
        Hidden
        Thumbnail {
          url
        }
        ProductLine {
          Name
          Slug
        }
        PriceRange
        Order
      }
    }
    blogPosts(
      limit: 3
      sort: "PublicationDate:DESC"
      where: { Visible: true }
    ) {
      id
      Title
      Perex
      Slug
      PublicationDate
      Categories {
        HidePublicationDates
      }
      CoverPhoto {
        url
      }
      created_at
    }
  }
`

const maxWidth = '1156px'

const useStyles = createUseStyles({
  container: {
    width: '100%',
    background: `url(${homePageTopBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '1053px 724.5px',
    backgroundPosition: '45vw -150px',
    paddingTop: '110px',
  },
  productNavigation: {
    maxWidth: maxWidth,
    margin: 'auto',
    marginTop: '135px',
  },
  productPreviews: {
    maxWidth: '1630px',
    margin: 'auto',
    marginTop: '77px',
  },
  blockPanel: {
    maxWidth: '1160px',
    margin: 'auto',
    marginTop: '87px',
  },
  newsletterPanel: {
    maxWidth: '1160px',
    margin: 'auto',
    marginTop: '140px',
  },
})

type Props = {}

const useRefArray = (length) => {
  return Array.from({ length }, () => React.createRef<HTMLDivElement>())
}

const HomePage: React.ComponentType<Props> = ({}) => {
  const partnerId = sessionStore?.getItem('partnerId')
  const { t } = useTranslation()
  const classes = useStyles()
  const [productLine, setProductLine] = useState<ProductLine[]>([])

  // This is a fix of not fetching updated data from CMS until FE gets build again. This should be fixed via webhooks in circleCI and CMS
  const { data } = useQuery(PRODUCT_LINES_QUERY)
  // const { cms } = useStaticQuery(GET_PRODUCT_LINES_QUERY)

  //create refs
  let sortedProductLines: ProductLine[] = []
  if (data && data['productLines']) {
    sortedProductLines = sortByOrder(data['productLines'])
  }

  const productLinesRefs = useRefArray(sortedProductLines.length)

  useEffect(() => {
    const sortedProductSubLine = []
    sortedProductLines.forEach((product: ProductLine) => {
      productLinesRefs[product.Name] = React.createRef()

      sortedProductSubLine.push({
        ...product,
        ProductSubLines: sortByOrder(
          product.ProductSubLines ? product.ProductSubLines : [],
        ),
      })
    })

    sortedProductSubLine.filter((item) => item.ProductSubLines.length > 0)

    setProductLine(sortedProductSubLine)
  }, [data])

  const ProductPanelWrapper = ({ product, productLinesRefs, classes }) => {
    return (
      <ProductPanel
        image={product.MoodPhoto.url}
        name={product.Name}
        description={product.Description}
        benefits={product.Benefits}
        link={product.Slug}
        productSets={product.ProductSubLines}
        productLineRef={productLinesRefs[product.Name]}
      />
    )
  }

  let ScrollableSection = ({ hash, key, children }) => {
    return <section>Product section</section>
  }
  if (typeof window !== 'undefined') {
    ScrollableSection = require('react-update-url-on-scroll').default
  }

  return (
    <div className={classes.container}>
      <ShopsHeader
        title={t('HOMEPAGE_TITLE', { interpolation: { escapeValue: false } })}
        icon={null}
      />
      <section className={classes.productNavigation}>
        <ProductNavigation
          productViews={productLine}
          productLinesRefs={productLinesRefs}
        />
      </section>
      {productLine.map((product: ProductLine, index) => {
        if (typeof window !== 'undefined') {
          return (
            <ScrollableSection hash={product.Slug} key={`product-${index}`}>
              <section className={classes.productPreviews}>
                <ProductPanelWrapper
                  product={product}
                  productLinesRefs={productLinesRefs}
                  classes={classes}
                />
              </section>
            </ScrollableSection>
          )
        } else {
          return (
            <section className={classes.productPreviews}>
              <ProductPanelWrapper
                product={product}
                productLinesRefs={productLinesRefs}
                classes={classes}
              />
            </section>
          )
        }
      })}

      {!partnerId && (
        <section className={classes.blockPanel}>
          {data && data['blogPosts'] && (
            <BlockPanel blockPosts={data['blogPosts']} />
          )}
        </section>
      )}
      {!partnerId && (
        <section className={classes.newsletterPanel}>
          <NewsletterPanel />
        </section>
      )}
    </div>
  )
}

export default HomePage
