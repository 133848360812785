import { ProductSubLine } from "../../../apollo/generated.d"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => {
  return {
    listItem: {
      textAlign: "center",
      padding: 10,
      width: "141px",
      height: "150px",
      boxShadow: "11px 11px 20px rgba(166, 171, 189, 0.4)",
      borderRadius: "4px",
      backgroundColor: "#fafafd",

      "& img": {
        height: "60px",
        padding: 0,
        margin: 0,
      },

      "&--active, &:hover": {
        boxShadow:
          "inset 2px 2px 4px rgba(166, 171, 189, 0.3), inset -2px -2px 4px #ffffff",
        backgroundImage: "linear-gradient(166deg, #fafafa 0%, #f0f3f8 100%)",
      },

      "&:hover": {
        cursor: "pointer",
      },

      "&-category": {
        color: theme.colors.darkGrey,
        fontSize: "12px",
        fontWeight: "300",
        letterSpacing: "0.55px",
        textTransform: "uppercase",
        marginTop: "8px",
      },

      "&-category--lonely": {
        fontWeight: "700",
      },

      "&-type": {
        color: theme.colors.darkGrey,
        fontSize: "14px",
        fontWeight: "700",
        textTransform: "uppercase",
      },
      "&-cost": {
        color: "#adb2cd;",
        fontSize: "11px",
        fontWeight: "700",
        textTransform: "uppercase",
        textAlign: "right",
        paddingTop: "10px",
        "& span": {
          color: theme.colors.darkGrey,
        },
      },
    },

    listItemFeatured: {
      textAlign: "center",
      padding: 10,
      width: "305px",
      height: "150px",
      boxShadow: "11px 11px 20px rgba(166, 171, 189, 0.4)",
      borderRadius: "4px",
      backgroundColor: "#fafafd",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "flex-start",
      alignItems: "center",

      "& img": {
        height: "110px",
        padding: 0,
        margin: 0,
      },

      "&--active, &:hover": {
        boxShadow:
          "inset 2px 2px 4px rgba(166, 171, 189, 0.3), inset -2px -2px 4px #ffffff",
        backgroundImage: "linear-gradient(166deg, #fafafa 0%, #f0f3f8 100%)",
      },

      "&:hover": {
        cursor: "pointer",
      },

      "&-category": {
        color: theme.colors.darkGrey,
        fontSize: "12px",
        fontWeight: "300",
        letterSpacing: "0.55px",
        textTransform: "uppercase",
        paddingTop: "20px",
      },

      "&-category--lonely": {
        fontWeight: "700",
      },

      "&-type": {
        color: theme.colors.darkGrey,
        fontSize: "18px",
        fontWeight: "700",
        textTransform: "uppercase",
      },
      "&-cost": {
        color: "#adb2cd;",
        fontSize: "13px",
        fontWeight: "700",
        textTransform: "uppercase",
        textAlign: "right",
        paddingTop: "5px",
        "& span": {
          color: theme.colors.darkGrey,
        },
      },
    },
  }
})

type DataProps = {
  product: ProductSubLine
}

const Item: React.FC<DataProps> = ({ product }) => {
  const classes = useStyles()

  const renderPriceTag = (priceLevel: number, featured: boolean) => {
    let output = null

    switch (priceLevel) {
      case 0:
        output = (
          <div
            className={`${
              featured ? classes.listItemFeatured : classes.listItem
            }-cost`}
          >
            $$$$
          </div>
        )
        break
      case 1:
        output = (
          <div
            className={`${
              featured ? classes.listItemFeatured : classes.listItem
            }-cost`}
          >
            <span>$</span>$$$
          </div>
        )
        break
      case 2:
        output = (
          <div
            className={`${
              featured ? classes.listItemFeatured : classes.listItem
            }-cost`}
          >
            <span>$$</span>$$
          </div>
        )
        break
      case 3:
        output = (
          <div
            className={`${
              featured ? classes.listItemFeatured : classes.listItem
            }-cost`}
          >
            <span>$$$</span>$
          </div>
        )
        break

      case 4:
        output = (
          <div
            className={`${
              featured ? classes.listItemFeatured : classes.listItem
            }-cost`}
          >
            <span>$$$$</span>
          </div>
        )
        break

      default:
        output = (
          <div
            className={`${
              featured ? classes.listItemFeatured : classes.listItem
            }-cost`}
          >
            $$$
          </div>
        )
        break
    }

    return output
  }

  const renderSmallItem = () => {
    return (
      <div className={`${classes.listItem}`} key={product.id}>
        {product.Thumbnail ? (
          <img
            src={product.Thumbnail.url}
            alt={`Vypínače ${product.ProductLine.Name} ${product.Name}`}
          />
        ) : (
          <div
            style={{ height: "60px", width: "60px", padding: 0, margin: 0 }}
          ></div>
        )}

        <div
          className={`${classes.listItem}-category ${
            product.Name && product.Name.length
              ? ""
              : `${classes.listItem}-category--lonely`
          }`}
        >
          {product.ProductLine.Name}
        </div>
        <div className={`${classes.listItem}-type`}>{product.Name}</div>
        {renderPriceTag(product.PriceRange, false)}
      </div>
    )
  }

  const renderFeaturedItem = () => {
    return (
      <div className={`${classes.listItemFeatured}`} key={product.id}>
        <div style={{ width: "50%", textAlign: "center", paddingTop: "10px" }}>
          {product.Thumbnail ? (
            <img
              src={product.Thumbnail.url}
              alt={`Vypínače ${product.ProductLine.Name} ${product.Name}`}
            />
          ) : (
            <div
              style={{ height: "110px", width: "110px", padding: 0, margin: 0 }}
            ></div>
          )}
        </div>

        <div
          style={{
            width: "50%",
            textAlign: "left",
            padding: "10px 5px",
          }}
        >
          <div
            className={`${classes.listItemFeatured}-category ${
              product.Name && product.Name.length
                ? ""
                : `${classes.listItemFeatured}-category--lonely`
            }`}
          >
            {product.ProductLine.Name}
          </div>
          <div className={`${classes.listItemFeatured}-type`}>
            {product.Name}
          </div>
          {renderPriceTag(product.PriceRange, true)}
        </div>
      </div>
    )
  }

  return product.Featured ? renderFeaturedItem() : renderSmallItem()
}

export default Item
