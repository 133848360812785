import ArrowButton from "components/Common/ArrowButton"
import CarouselList from "components/Common/CarouselList"
import ProductItem from "./Item"
import { ProductSubLine } from "apollo/generated.d"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
  list: {
    width: "100%",
    position: "relative",
  },

  listItem: {
    margin: "0px 8.73px",
  },

  buttons: {
    flex: "0",
    display: "flex",
    justifyContent: "left",
    alignItems: "left",
    minWidth: "max(16%, 120px)",

    "&--single-button": {
      minWidth: "max(16%, 60px)",

      "&>*": {
        marginRight: "min(50px, 20%)",
      },
    },

    "&>*:nth-child(2)": {
      marginLeft: 21,
    },
  },

  fadeDiv: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    "& div": {
      width: "30%",
      height: "100%",
      backgroundImage:
        "linear-gradient(to left, #eff0f5 0%, rgba(255, 255, 255, 0) 100%)",
      float: "right",
    },
  },
})

const itemsToShow = 9

type DataProps = {
  productSets: ProductSubLine[]
  handleItemClick: (productSubLine: ProductSubLine) => void
}

type StateProps = {
  activeIndex: number
  containerWidth: number
}

const List: React.FC<DataProps> = ({ productSets, handleItemClick }) => {
  const classes = useStyles()

  const [state, setState] = React.useState<StateProps>({
    activeIndex: 0,
    containerWidth: 630,
  })

  const calculateItemsWidth = (startIndex: number, items: number[]) => {
    let output = 0
    if (items) {
      output = (items.length - startIndex) * 140
    }
    return output
  }

  const disableRight = (idsForTopRow: number[]) => {
    const calculatedWidth = calculateItemsWidth(state.activeIndex, idsForTopRow)
    return calculatedWidth < state.containerWidth - 80
  }

  const disableLeft = () => {
    return state.activeIndex < 1
  }

  const productElementsForIds = (ids: number[]) => {
    const output = []
    let lastId = -1

    ids.forEach((id, index) => {
      const product = productSets[id]
      if (lastId !== id) {
        output.push(
          <span
            className={classes.listItem}
            onClick={() => handleItemClick(product)}
            key={product.id}
          >
            <ProductItem product={product} />
          </span>
        )
      } else {
        output.push(<span key={`${product.id}-span`} />)
      }
      lastId = id
    })

    return output
  }

  let idsForRows = []
  let idsForTopRow = []
  let idsForBottomRow = []
  productSets.forEach((product, index) => {
    idsForRows.push(index)
    if (product.Featured) {
      idsForRows.push(index)
    }
  })

  if (idsForRows.length <= 3) {
    idsForTopRow = idsForRows
  } else {
    let pivot = Math.round(idsForRows.length / 2) - 1
    if (idsForRows[pivot] === idsForRows[pivot + 1]) {
      pivot += 1
    }
    idsForTopRow = idsForRows.slice(0, pivot + 1)
    idsForBottomRow = idsForRows.slice(pivot + 1, idsForRows.length)
  }

  const itemsForTopRow = productElementsForIds(idsForTopRow)
  const itemsForBottomRow = productElementsForIds(idsForBottomRow)

  const calculatedWidth = calculateItemsWidth(0, idsForTopRow)
  let showArrowButtons = !(calculatedWidth < state.containerWidth - 80)

  return (
    <div>
      <section className={classes.list}>
        <div style={{ marginTop: "50px" }}>
          <CarouselList
            height="200px"
            speed=".3s"
            paddingX={4}
            fullWidth={true}
            showItems={idsForTopRow.length}
            activeIndex={state.activeIndex}
            items={itemsForTopRow}
            alignLeft={true}
            itemWidth={140}
            containerWidthChanged={width => {
              setState({ ...state, containerWidth: width })
            }}
          />
        </div>
        {itemsForBottomRow && itemsForBottomRow.length > 0 && (
          <div style={{ marginTop: "-20px" }}>
            <CarouselList
              height="200px"
              speed=".3s"
              paddingX={4}
              fullWidth={true}
              showItems={idsForBottomRow.length}
              activeIndex={state.activeIndex}
              items={itemsForBottomRow}
              alignLeft={true}
              itemWidth={140}
            />
          </div>
        )}

        <div className={classes.fadeDiv}>
          <div></div>
        </div>
      </section>

      {showArrowButtons && (
        <div className={classes.buttons}>
          <ArrowButton
            direction="left"
            disabled={disableLeft()}
            onClick={() => {
              const product = productSets[idsForTopRow[state.activeIndex - 1]]
              const prevProduct =
                productSets[idsForTopRow[state.activeIndex - 2]]

              let pageSize = 1

              if (product && prevProduct && state.activeIndex > 1) {
                if (product.id === prevProduct.id) {
                  pageSize = 2
                }
              }

              setState({ ...state, activeIndex: state.activeIndex - pageSize })
            }}
          />
          <ArrowButton
            direction="right"
            disabled={disableRight(idsForTopRow)}
            onClick={() => {
              const product = productSets[idsForTopRow[state.activeIndex]]
              const nextProduct =
                productSets[idsForTopRow[state.activeIndex + 1]]

              let pageSize = 1

              if (product && nextProduct) {
                if (product.id === nextProduct.id) {
                  pageSize = 2
                }
              }

              setState({ ...state, activeIndex: state.activeIndex + pageSize })
            }}
          />
        </div>
      )}
    </div>
  )
}

List.defaultProps = {
  productSets: [],
}

export default List
