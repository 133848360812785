import GlobalClasses from "styles/global"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => {
  return {
    ...GlobalClasses,
    btn: {
      width: "32px",
      height: "32px",
      borderRadius: "100px",
      border: "1px solid #ADB2CD",

      transition: ".1s all ease",

      "&:focus": {
        outline: "none",
      },

      "& svg": {
        fill: `${theme.colors.darkGrey} !important`,
        transition: ".1s all ease",
        "&.left": {
          transform: "rotate(180deg) translate(0px, 2px)",
        },
        "&.up": {
          transform: "rotate(-90deg) translate(1px, 1px)",
        },
        "&.down": {
          transform: "rotate(90deg) translate(-1px, 1px)",
        },
      },
    },

    default: {
      background: "none",
      "&:hover": {
        borderColor: theme.colors.primaryGreen,
        "& svg": {
          fill: `${theme.colors.primaryGreen} !important`,
        },
      },
    },

    disabled: {
      borderColor: "rgba(0,0,0,0)",
      opacity: "0.3",
      backgroundColor: "#ADB2CD",
    },
  }
})

type DataProps = {
  disabled: boolean
  direction: "left" | "right" | "up" | "down"
  onClick: () => void
}

const ArrowButton: React.FC<DataProps> = ({ disabled, direction, onClick }) => {
  const classes = useStyles()

  return (
    <button
      disabled={disabled}
      className={`${classes.btn} ${
        disabled ? classes.disabled : classes.default
      }`}
      onClick={onClick}
    >
      <svg
        width="7px"
        height="12px"
        viewBox="0 0 9 14"
        className={`${direction}`}
      >
        <path
          transform="translate(-391.000000, -2021.000000)"
          d="M400.242619,2026.7509 C400.244217,2026.414 400.111807,2026.09028 399.874552,2025.85108 L394.797979,2020.7745 C394.476503,2020.45257 394.0077,2020.3266 393.568163,2020.44404 C393.128627,2020.56149 392.785132,2020.9045 392.667071,2021.34387 C392.54901,2021.78324 392.674319,2022.25222 392.995795,2022.57415 L397.183968,2026.7509 L392.995795,2030.9175 C392.499187,2031.41516 392.500039,2032.22117 392.997699,2032.71778 C393.495358,2033.21438 394.301371,2033.21353 394.797979,2032.71587 L399.874552,2027.6393 C400.109019,2027.40291 400.241202,2027.08385 400.242619,2026.7509 Z"
        />
      </svg>
    </button>
  )
}

export default ArrowButton
