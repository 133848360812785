import React from "react"
import { createUseStyles } from "react-jss"
import { useTranslation } from "react-i18next"

const mockItems = ["Profesionál", "Zákazník hledající inspiraci"]

const useStyles = createUseStyles(theme => ({
  wrapper: {
    maxWidth: "1140px",
    display: 'flex',
    flexDirection: 'column',
    boxShadow:
      "11px 11px 20px rgba(166, 171, 189, 0.4), -11px -11px 20px #fafbff",
    borderRadius: "10px",
    backgroundColor: "#eff0f3",
    margin: "auto",
    textAlign: "center",
    [`@media (max-width: ${theme.breakpoints.S})`]: {
      height: "inherit",
    },
  },
  title: {
    minHeight: "62px",
    color: theme.colors.darkGrey,
    fontFamily: "Nunito",
    fontSize: "24px",
    fontWeight: 600,
    letterSpacing: "1px",
    paddingTop: "40px",
    textAlign: "center",
    width: "100%",
    textTransform: "uppercase",
    marginBottom: "5px",
  },

  main: {
    width: "100%",
  },

  loadButton: {
    margin: "auto",
    marginTop: "3px",
    marginBottom: "40px",
    width: "186px",
    height: "41px",
    boxShadow: "2px 2px 5px rgba(80, 178, 125, 0.51), -2px -2px 5px #ffffff",
    borderRadius: "10px",
    backgroundColor: theme.colors.primaryGreen,
    paddingTop: "8px",
    textAlign: "center",
    textDecoration: "none !important",
    "&:hover": {
      backgroundColor: "#2bad47",
      textDecoration: "none !important",
    },
    "&:focus": {
      outline: "none !important",
      boxShadow: "none",
    },
    "&:active": {
      outline: "none !important",
      boxShadow: "none",
    },
    "& span": {
      color: "#ffffff",
      fontFamily: "Nunito",
      fontSize: "13px",
      fontWeight: 700,
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
  },

  description: {
    color: theme.colors.primaryGrey,
    fontSize: '13px',
    marginTop: '10px',
    fontWeight: '400',
    letterSpacing: '0.54px',
    marginBottom: '25px',
  },

}))


const NewsletterPanel: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.main}>
      <div className={classes.wrapper}>
        <div className={classes.title}>{t("HOMEPAGE_NEWSLETTER_TITLE")}</div>
        <div className={classes.description}>
        {t("HOMEPAGE_NEWSLETTER_DESCRIPTION")}
        </div>
       
          <a
            className={classes.loadButton}
            target="_blank"
            href="https://go.schneider-electric.com/CZ_202002_JVA-Landingpage-optin-content_EA-LP.html?source=Content&sDetail=JVA-Landingpage-optin-content_CZ"
          >
            <span>{t("HOMEPAGE_NEWSLETTER_BUTTON")}</span>
          </a>
      </div>
    </div>
  )
}

export default NewsletterPanel
