import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  list: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },

  item: {
    position: 'absolute',
    opacity: 1,
  },

  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
})

type DataProps = {
  items: any[]
  height: string
  showItems: number
  activeIndex: number
  speed: string
  paddingX: number
  fullWidth: boolean
  alignLeft?: boolean
  containerWidthChanged?: (width: number) => void
  itemWidth?: number
}

type StateProps = {
  containerWidth: number
  itemWidth: number
}

const CarouselList: React.FC<DataProps> = ({
  items,
  height,
  showItems,
  fullWidth,
  activeIndex,
  speed,
  paddingX,
  alignLeft,
  containerWidthChanged,
  itemWidth,
}) => {
  const classes = useStyles()

  const [state, setState] = React.useState<StateProps>({
    containerWidth: 0,
    itemWidth: 0,
  })

  // DIMENSIONS
  const containerRef = React.useRef<HTMLDivElement>()
  const firstItemRef = React.useRef<HTMLDivElement>()
  const showItemsFinal =
    fullWidth && showItems > items.length ? items.length : showItems

  const resizeContainer = () => {
    containerWidthChanged &&
      containerWidthChanged(containerRef.current?.offsetWidth)

    setState({
      ...state,
      containerWidth: containerRef?.current?.offsetWidth || 0,
      itemWidth: itemWidth || firstItemRef?.current?.offsetWidth || 0,
    })
  }

  React.useLayoutEffect(() => {
    window.addEventListener('resize', resizeContainer)
    resizeContainer()
    return () => window.removeEventListener('resize', resizeContainer)
  }, [])

  React.useEffect(() => {
    // items might not be present after first mount
    if (items.length > 0) {
      resizeContainer()
    }
  }, [items.length])

  return (
    <div ref={containerRef} className={`${classes.list}`} style={{ height }}>
      {items.map((item, index) => (
        <div
          className={`${classes.item} ${
            index < activeIndex || index >= activeIndex + showItemsFinal
              ? classes.hidden
              : ''
          }`}
          key={index}
          style={{
            left: alignLeft
              ? (index - activeIndex) * (state.itemWidth + paddingX + 20)
              : (index - activeIndex + 0.5) *
                  ((state.containerWidth - paddingX * 2) / showItemsFinal) -
                state.itemWidth / 2 +
                paddingX,
            transition: `all ${speed} ease`,
          }}
          ref={index === 0 ? firstItemRef : null}
        >
          {item}
        </div>
      ))}
    </div>
  )
}

export default CarouselList
