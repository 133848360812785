export const trackVizualizator = () => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || []
  // @ts-ignore
  window.dataLayer.push({ event: "vizualizator", category: "vizualizator" })
}

const partners = {
  1: "sonepar",
  2: "emas",
  4: "elektrosms",
}

// GTM analytics, to track user is in iframe
export const trackIframe = (partnerId: string) => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || []
  // @ts-ignore
  window.dataLayer.push({
    event: "iframeData",
    partnerId: partnerId,
    isIframe: true,
    websiteIframe: partners[partnerId] || partnerId,
  })
}

// GTM analytics, to track user final action (click on OBJEDNAT button)
export const trackLead = (partnerId: string) => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || []
  // @ts-ignore
  window.dataLayer.push({
    event: "generate_lead",
    partnerId: partnerId,
    isIframe: true,
    websiteIframe: partners[partnerId] || partnerId,
  })
}

// GTM analytics, to track user behaviour on the page
export const trackStep = (partnerId: string, step: number) => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || []
  // @ts-ignore
  window.dataLayer.push({
    event: "iframe_checkout",
    eventModel: {
      step: step,
      partnerId: partnerId,
      isIframe: true,
      websiteIframe: partners[partnerId] || partnerId,
    },
  })
}
