import { Col, Image, Row } from 'react-bootstrap'
import {
  ComponentListsTextArray,
  ProductSubLine,
} from '../../../apollo/generated.d'

import GlobalClasses from '../../../styles/global'
import List from './List'
import React from 'react'
import {
  configuratorInitLineSlug,
  configuratorInitSubLineSlug,
  defaultCart,
  defaultVisualizer,
  updateCustomerSet,
} from 'apollo/reactive-variables/configuratorVariables'
import { createUseStyles } from 'react-jss'
import { navigate } from 'gatsby'
import { useQuery } from '@apollo/client'
import { GET_CUSTOMER_SETS } from 'apollo/static-queries/configuratorQueries'

const useStyles = createUseStyles((theme) => ({
  ...GlobalClasses,

  container: {
    padding: 0,
    width: '100%',
    maxWidth: '1500px',
    margin: 'auto',
  },

  paddingTop: {
    paddingTop: '80px',
  },

  leftImage: {
    width: '100%',
    maxWidth: '811px',
    borderRadius: '0px 0px 50px 0px',
  },
  rightContent: {
    margin: 0,
    padding: '90px 4% 0px 4%',
    '&-features-wrapper': {
      margin: 0,
      padding: 0,
      marginTop: '56px',
    },
    '&-features-left': {
      padding: '12px 12px 12px 25px',
      margin: 'auto',
    },
    '&-features-center': {
      padding: '12px 12px 12px 25px',
      borderLeft: '1px solid rgba(119, 123, 145, 0.5)',
      borderRight: '1px solid rgba(119, 123, 145, 0.5)',
      margin: 'auto',
    },
    '&-features-right': {
      padding: '12px 12px 12px 25px',
      margin: 'auto',
    },
    '@media (max-width: 768px)': {
      '&-features-left': {
        padding: '12px',
        margin: 'auto',
      },
      '&-features-center': {
        padding: '12px',
        borderLeft: '1px solid rgba(119, 123, 145, 0.5)',
        borderRight: '1px solid rgba(119, 123, 145, 0.5)',
        margin: 'auto',
      },
      '&-features-right': {
        padding: '12px',
        margin: 'auto',
      },
    },
  },

  title: {
    color: theme.colors.darkGrey,
    fontSize: '40px',
    fontWeight: 600,
    letterSpacing: '2.22px',
    textTransform: 'uppercase',
    paddingLeft: '14px;',
    marginBottom: 0,
    lineHeight: 1.5,
  },
  titlePrefix: {
    width: '5px',
    background: '#3CCD58',
    borderRadius: '4px',
    height: '40px',
    marginTop: '10px',
  },

  description: {
    color: theme.colors.darkGrey,
    fontSize: '14px',
    fontWeight: 300,
    marginLeft: '25px',
    letterSpacing: '0.58px',
    maxWidth: '320px',
    marginTop: '6px',
  },

  featureText: {
    color: theme.colors.darkGrey,
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.58px',
  },

  noPaddingMargin: {
    margin: 0,
    padding: 0,
  },
  '@media (max-width: 414px)': {
    rightContent: {
      '&-features-wrapper': {
        display: 'flex',
        flexDirection: 'column',
      },
      '&-features-left': {
        padding: '12px 0 12px 0',
      },
      '&-features-center': {
        padding: '12px 0 12px 0',
        borderTop: '1px solid rgba(119, 123, 145, 0.5)',
        borderBottom: '1px solid rgba(119, 123, 145, 0.5)',
        borderLeft: 'none',
        borderRight: 'none',
        margin: 'auto',
      },
      '&-features-right': {
        padding: '12px 0 12px 0',
      },
    },
  },
}))

type DataProps = {
  image: any
  name: string
  description: string
  benefits: ComponentListsTextArray[]
  link: string
  productSets: ProductSubLine[]
  productLineRef: React.RefObject<HTMLDivElement>
}

const ProductPanel: React.ComponentType<DataProps> = ({
  image,
  name,
  description,
  benefits,
  link,
  productSets,
  productLineRef,
}) => {
  const classes = useStyles()

  const { data } = useQuery(GET_CUSTOMER_SETS)
  let ACTIVE_CUSTOMER_SET = data.activeCustomerSet

  return (
    <div id={link} ref={productLineRef} className={classes.paddingTop}>
      <Row className={classes.container}>
        <Col xs={12} md={6} className={classes.noPaddingMargin}>
          <Image
            src={image}
            className={classes.leftImage}
            alt={`Vypínače ${name}`}
          ></Image>
        </Col>
        <Col xs={12} md={6} className={classes.rightContent}>
          <div style={{ display: 'flex' }}>
            <span className={classes.titlePrefix}></span>
            <h2 className={classes.title}>{name}</h2>
          </div>
          <div className={classes.description}>{description}</div>

          <Row className={`${classes.rightContent}-features-wrapper`}>
            <Col xs={4} className={`${classes.rightContent}-features-left`}>
              <span className={classes.featureText}>
                {benefits && benefits[0]
                  ? benefits[0]['BenefitDescription']
                  : ''}
              </span>
            </Col>
            <Col xs={4} className={`${classes.rightContent}-features-center`}>
              <span className={classes.featureText}>
                {benefits && benefits[1]
                  ? benefits[1]['BenefitDescription']
                  : ''}
              </span>
            </Col>
            <Col xs={4} className={`${classes.rightContent}-features-right`}>
              <span className={classes.featureText}>
                {benefits && benefits[2]
                  ? benefits[2]['BenefitDescription']
                  : ''}
              </span>
            </Col>
          </Row>

          <List
            productSets={productSets}
            handleItemClick={(productSubLine) => {
              updateCustomerSet(ACTIVE_CUSTOMER_SET.id, {
                ...ACTIVE_CUSTOMER_SET,
                cart: defaultCart,
                productLineSlug: productSubLine.ProductLine.Slug,
                productSubLineSlug: productSubLine.Slug,
                visualizer: {
                  ...ACTIVE_CUSTOMER_SET.visualizer,
                  selectedParts: defaultVisualizer.selectedParts,
                },
              })
              configuratorInitSubLineSlug(productSubLine.Slug)
              configuratorInitLineSlug(productSubLine.ProductLine.Slug)
              navigate(
                `/vizualizator/${productSubLine.ProductLine.Slug}/${productSubLine.Slug}`,
              )
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

export default ProductPanel
